import { AccountRights } from "@vaultinum/vaultinum-api";
import {
    AccountIcon,
    CompanyIcon,
    ContentLoader,
    CreditCardIcon,
    GithubIcon,
    KeyIcon,
    SubItemProps,
    ToggleIcon,
    UserIcon,
    UsersIcon,
    addQueryParamsToUrl,
    isDomainOwner,
    useAuthContext,
    useHasAccountRights,
    useLang,
    useNavigationMenuContext,
    useUrlSearch,
    useWhiteLabelContext
} from "@vaultinum/vaultinum-sdk";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MenuItemKeys, SubMenuItemKeys, isMenuItemEnabled } from "../../../helpers";
import { AccountLang } from "../../../lang/AccountLang";
import { URL } from "../../../services";
import ApiKeysView from "./ApiKeysView";
import BillingSubscriptionView from "./BillingSubscriptionView";
import CompanyAccounts from "./CompanyAccounts";
import CompanyInformationView from "./CompanyInformationView";
import GitConnectionsView from "./GitConnectionsView";
import PartnerView from "./PartnerView";
import PartnersView from "./PartnersView";
import PersonalInformationView from "./PersonalInformationView";
import PreferencesView from "./PreferencesView";
import UserAccessView from "./UserAccessView";

function getProfileSubItems(lang: AccountLang): SubItemProps[] {
    return [
        {
            key: SubMenuItemKeys.PROFILE,
            href: addQueryParamsToUrl(URL.settings.index, { tab: SubMenuItemKeys.PROFILE }),
            text: lang.profileSettings.form.personalInformation,
            icon: UserIcon
        },
        {
            key: SubMenuItemKeys.ACCOUNTS,
            href: addQueryParamsToUrl(URL.settings.index, { tab: SubMenuItemKeys.ACCOUNTS }),
            text: lang.profileSettings.menu.companyAccounts,
            icon: CompanyIcon
        },
        {
            key: SubMenuItemKeys.PREFERENCES,
            href: addQueryParamsToUrl(URL.settings.index, { tab: SubMenuItemKeys.PREFERENCES }),
            text: lang.profileSettings.menu.preferences,
            icon: ToggleIcon
        }
    ];
}

function getOrganisationSubItems(lang: AccountLang, isBillingMenuVisible?: boolean): SubItemProps[] {
    const subItems: SubItemProps[] = [
        {
            key: SubMenuItemKeys.ORGANISATION,
            href: addQueryParamsToUrl(URL.settings.index, { tab: SubMenuItemKeys.ORGANISATION }),
            text: lang.accountSettings.menu.generalInformation,
            icon: CompanyIcon
        },
        {
            key: SubMenuItemKeys.USERS,
            href: addQueryParamsToUrl(URL.settings.index, { tab: SubMenuItemKeys.USERS }),
            text: lang.accountSettings.menu.userAccess,
            icon: AccountIcon
        }
    ];

    if (isMenuItemEnabled(SubMenuItemKeys.BILLING, isBillingMenuVisible)) {
        subItems.push({
            key: SubMenuItemKeys.BILLING,
            href: addQueryParamsToUrl(URL.settings.index, { tab: SubMenuItemKeys.BILLING }),
            text: lang.accountSettings.menu.billingAndSubscriptions,
            icon: CreditCardIcon
        });
    }

    if (isMenuItemEnabled(SubMenuItemKeys.PARTIES)) {
        subItems.push({
            key: SubMenuItemKeys.PARTIES,
            href: addQueryParamsToUrl(URL.settings.index, { tab: SubMenuItemKeys.PARTIES }),
            text: lang.accountSettings.menu.parties,
            icon: UsersIcon
        });
    }

    if (isMenuItemEnabled(SubMenuItemKeys.GIT)) {
        subItems.push({
            key: SubMenuItemKeys.GIT,
            href: addQueryParamsToUrl(URL.settings.index, { tab: SubMenuItemKeys.GIT }),
            text: lang.accountSettings.menu.git,
            icon: GithubIcon
        });
    }

    subItems.push({
        key: SubMenuItemKeys.API_KEYS,
        href: addQueryParamsToUrl(URL.settings.index, { tab: SubMenuItemKeys.API_KEYS }),
        text: lang.accountSettings.menu.apiKeys,
        icon: KeyIcon
    });

    return subItems;
}

export default function SettingsPage(): JSX.Element {
    const { tab, id } = useUrlSearch() as { tab?: string; id?: string };
    const navigate = useNavigate();
    const lang = useLang<AccountLang>();
    const { userProfile, claims, accountUser } = useAuthContext();
    const { setSubMenuItems, activeMenuItemKey } = useNavigationMenuContext();
    const { whiteLabelDomain } = useWhiteLabelContext();
    const isAdmin = useHasAccountRights(AccountRights.ADMIN);

    useEffect(() => {
        if (activeMenuItemKey === MenuItemKeys.SETTINGS) {
            const isBillingMenuVisible = whiteLabelDomain ? isDomainOwner(claims, whiteLabelDomain?.id) : isAdmin;
            setSubMenuItems([
                {
                    text: lang.profileSettings.menu.profile,
                    items: getProfileSubItems(lang)
                },
                {
                    text: lang.accountSettings.menu.organisation,
                    items: getOrganisationSubItems(lang, isBillingMenuVisible)
                }
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang, claims, whiteLabelDomain, isAdmin, activeMenuItemKey]);

    if (!claims || !userProfile || !accountUser) {
        return <ContentLoader />;
    }

    if (!tab) {
        navigate(addQueryParamsToUrl(URL.settings.index, { tab: SubMenuItemKeys.PROFILE }));
    }

    switch (tab) {
        case SubMenuItemKeys.PROFILE:
            return <PersonalInformationView />;
        case SubMenuItemKeys.PREFERENCES:
            return <PreferencesView />;
        case SubMenuItemKeys.ACCOUNTS:
            return <CompanyAccounts />;
        case SubMenuItemKeys.ORGANISATION:
            return <CompanyInformationView />;
        case SubMenuItemKeys.USERS:
            return <UserAccessView />;
        case SubMenuItemKeys.BILLING:
            return <BillingSubscriptionView />;
        case SubMenuItemKeys.PARTIES:
            if (id) {
                return <PartnerView />;
            }
            return <PartnersView />;
        case SubMenuItemKeys.GIT:
            return <GitConnectionsView />;
        case SubMenuItemKeys.API_KEYS:
            return <ApiKeysView />;
        default:
            return <PersonalInformationView />;
    }
}
