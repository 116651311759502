import classNames from "classnames";
import { motion } from "framer-motion";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { DropdownActionProps, Dropdowns, FunnelIcon, LoadingIcon, Modal, Select, Separator, Spin, openNotificationWithIcon } from "../../../design-system";
import { CommonLang, useLang } from "../../../lang";
import { BaseTrackingEvents, trackingService } from "../../analytics";
import { BannerType, LayoutContextProvider, useAppContext, useAuthContext } from "../../contexts";
import { getAppNameForTrackingService, getMatchingItem } from "../../helpers";
import { useAvailableAppItems } from "../../hooks";
import { SHARED_URL, logout } from "../../services";
import { AppMenuItem } from "./AppsDropdownMenu";
import { DropdownItem } from "./DropdownMenu";
import LeftNavbar, { MenuItem } from "./LeftNavBar";
import { NavigationMenuContextProvider } from "./NavigationMenuContext";
import SubMenuItem, { SubItemProps, SubMenuItemProps } from "./SubMenuItem";
import TopNavBar from "./TopNavBar";

function isSubItemProps(item: SubItemProps | string): item is SubItemProps {
    return typeof item !== "string";
}

function getActiveMenuItem(location: Pick<Location, "pathname" | "search">, menuItems?: MenuItem[][]): MenuItem | undefined {
    return menuItems ? getMatchingItem(location, menuItems.flat()) : undefined;
}

function getActiveSubMenuItem(location: Pick<Location, "pathname" | "search">, subMenuItems: SubMenuItemProps[]): SubItemProps | undefined {
    return getMatchingItem(
        location,
        subMenuItems.flatMap(subMenuItem => subMenuItem.items.filter(isSubItemProps))
    );
}
function LayoutBanner({ type, children }: { type: BannerType; children: ReactNode }) {
    return (
        <div
            className={classNames("borderp-4 flex w-full rounded-md p-4", {
                "border-orange-light bg-orange-extra-light": type === "warning",
                "border-green-light bg-green-extra-light": type === "success",
                "border-red-light bg-red-extra-light": type === "error",
                "border-blue-light bg-blue-extra-light": type === "info"
            })}
            children={children}
        />
    );
}

function getLoadingMenutItems(lang: CommonLang): MenuItem[][] {
    return [
        [
            {
                key: "loading",
                icon: LoadingIcon,
                text: lang.shared.loading,
                href: "#"
            }
        ]
    ];
}

export type NaviguationMenuLayout = "logout-only" | "minimal" | "full";

export default function NavigationMenu<TSelectItem>({
    children,
    dropdownItems = [],
    layout,
    menuItems,
    hideExpandButton,
    isMenuDefaultExpanded = true
}: {
    children: ReactNode;
    dropdownItems?: DropdownItem[];
    layout?: NaviguationMenuLayout;
    menuItems?: MenuItem[][];
    hideExpandButton?: boolean;
    isMenuDefaultExpanded?: boolean;
}): JSX.Element {
    const [subMenuItems, setSubMenuItems] = useState<SubMenuItemProps[] | undefined>();
    const [activeMenuItemKey, setActiveMenuItemKey] = useState<string | undefined>();
    const [activeSubMenuItemKey, setActiveSubMenuItemKey] = useState<string | undefined>();
    const [loggingOut, setLoggingOut] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const { layoutBanner } = useAppContext();
    const { userProfile } = useAuthContext();
    const lang = useLang<CommonLang>();
    const appsMenuItems = useAvailableAppItems();
    const navigate = useNavigate();
    const contentRef = useRef<HTMLDivElement>(null);
    const [contentHeight, setContentHeight] = useState<number | undefined>();
    const [selectOptions, setSelectOptions] = useState<{ value: TSelectItem; label: string }[]>([]);
    const [selectedOption, setSelectedOption] = useState<TSelectItem>();
    const [optionsActions, setOptionsActions] = useState<DropdownActionProps[] | undefined>();

    useEffect(() => {
        function handleResize() {
            setContentHeight(contentRef.current?.clientHeight);
        }
        if (contentRef.current) {
            handleResize();
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [contentRef.current]);

    const menuVariants = {
        open: { width: 225 },
        closed: { width: 0 }
    };

    async function onLogout(): Promise<void> {
        try {
            setLoggingOut(true);
            await logout();
        } catch (error) {
            openNotificationWithIcon({ type: "error", description: `${lang.shared.logoutFailed}` });
        }
        setLoggingOut(false);
        window.location.assign(SHARED_URL.app.login);
    }

    function onAppItemClick(item: AppMenuItem) {
        trackingService.sendEvent(BaseTrackingEvents.AppLogoClicked, {
            App: getAppNameForTrackingService(item.href),
            Source: "Apps Dropdown"
        });
    }

    function onSetSelectOptions(options: { value: TSelectItem; label: string }[]): void {
        setSelectedOption(options.length === 0 ? undefined : options[0].value);
        setSelectOptions(options);
    }

    const location = useLocation();

    useEffect(() => {
        const activeMenuItem = getActiveMenuItem(location, menuItems);
        if (activeMenuItem?.key === activeMenuItemKey) {
            return;
        }
        setSubMenuItems([]);
        setOptionsActions([]);
        setSelectOptions([]);
        setActiveMenuItemKey(activeMenuItem?.key);
    }, [location, menuItems, activeMenuItemKey]);

    useEffect(() => {
        if (subMenuItems?.length) {
            setIsExpanded(!!subMenuItems?.length);
            const activeSubMenuItem = getActiveSubMenuItem(location, subMenuItems);
            setActiveSubMenuItemKey(activeSubMenuItem?.key);
        }
    }, [location, subMenuItems]);

    function onMenuItemClick(menuItem: MenuItem): void {
        menuItem.onClick?.();
        const nextUrl = menuItem.href;
        if (nextUrl) {
            navigate(nextUrl);
        }
    }

    const isOpen = isExpanded && subMenuItems?.length;

    const hasSelectOptions = !!selectOptions.length;
    const hasActions = !!optionsActions?.length;
    return (
        <NavigationMenuContextProvider
            value={{
                activeMenuItemKey,

                setSubMenuItems,
                subMenuItems,

                selectOptions,
                setSelectOptions: onSetSelectOptions,

                selectedOption,
                setOptionsActions
            }}
        >
            <div className="flex h-screen">
                <LeftNavbar
                    menuItems={menuItems === undefined ? getLoadingMenutItems(lang) : menuItems}
                    dropdownItems={dropdownItems}
                    homeHref="/app"
                    onMenuItemClick={onMenuItemClick}
                    activeMenuItemKey={activeMenuItemKey}
                    hideExpandButton={hideExpandButton}
                    isMenuDefaultExpanded={isMenuDefaultExpanded}
                />
                <div className="flex flex-1 flex-col overflow-x-auto">
                    <TopNavBar onLogout={onLogout} userProfile={userProfile} appsMenuItems={appsMenuItems} onAppItemClick={onAppItemClick} layout={layout} />
                    <div className="flex h-full overflow-hidden">
                        {subMenuItems && (
                            <motion.div
                                className="h-full overflow-y-auto overflow-x-hidden bg-white shadow-inner"
                                variants={menuVariants}
                                initial={isOpen ? "open" : "closed"}
                                animate={isOpen ? "open" : "closed"}
                            >
                                <div
                                    className={classNames({
                                        "flex h-20 items-center justify-between px-4 text-slate-dark": hasSelectOptions || hasActions
                                    })}
                                >
                                    {hasSelectOptions && (
                                        <Select.Basic
                                            icon={FunnelIcon}
                                            className={classNames({ "w-4/5": hasActions, "w-full": !hasActions })}
                                            value={selectedOption}
                                            options={selectOptions}
                                            onChange={setSelectedOption}
                                        />
                                    )}
                                    {hasActions && (
                                        <div className="ml-auto">
                                            <Dropdowns.Options actions={optionsActions} isLoading={false} />
                                        </div>
                                    )}
                                </div>
                                {subMenuItems.map(subMenuItem => {
                                    return (
                                        <div key={subMenuItem.text}>
                                            <SubMenuItem subMenuItem={subMenuItem} activeSubMenuItemKey={activeSubMenuItemKey} />
                                            <Separator />
                                        </div>
                                    );
                                })}
                            </motion.div>
                        )}
                        <div ref={contentRef} className="flex flex-1 flex-col overflow-hidden bg-grey-extra-light shadow-inner">
                            {layoutBanner && <LayoutBanner {...layoutBanner} />}
                            <div className="overflow-y-auto p-4">
                                <LayoutContextProvider value={{ contentHeight }} children={children} />
                            </div>
                        </div>
                    </div>
                </div>
                {loggingOut && (
                    <Modal isOpen hideFooter={true} isLoading lang={lang} size="sm">
                        <div className="flex justify-center space-x-4">
                            <Spin /> <span className="text-base font-semibold">{lang.shared.logOutProgress}</span>
                        </div>
                    </Modal>
                )}
            </div>
        </NavigationMenuContextProvider>
    );
}
