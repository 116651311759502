/* eslint-disable import/no-unused-modules */
import classNames from "classnames";
import ReactSelect, { DropdownIndicatorProps, GroupBase, Props, OnChangeValue as RSOnChangeValue, components } from "react-select";
import RSCreatableSelect, { CreatableProps as RSCreatableProps } from "react-select/creatable";
import { Overwrite } from "../../../helpers";
import { BaseInputs } from "./BaseInput";
import { ErrorMessage } from "./ErrorMessage";
import { Label } from "./Label";

export type Option<T> = { label: React.ReactNode; value: T; disabled?: boolean };
type Value<T> = T | T[];
type OnChangeValue<T, IsMulti extends boolean> = IsMulti extends true ? T[] : T;
export type SelectProps<T, IsMulti extends boolean = false> = Overwrite<
    Props<Option<T>, IsMulti>,
    { label?: React.ReactNode; onChange: (value: OnChangeValue<T, IsMulti>) => void; value?: Value<T>; defaultValue?: Value<T> }
> &
    BaseInputs.Props;

export type CreatableProps<T, IsMulti extends boolean, Group extends GroupBase<Option<T>>> = Overwrite<
    RSCreatableProps<Option<T>, IsMulti, Group>,
    { onChange: (value: OnChangeValue<T, IsMulti>) => void; value?: T[] }
> &
    BaseInputs.Props;

function getValue<T, IsMulti extends boolean>(option: RSOnChangeValue<Option<T>, IsMulti>): OnChangeValue<T, IsMulti>;
function getValue<T, IsMulti extends boolean>(option: RSOnChangeValue<Option<T>, IsMulti>): Value<T> {
    if (Array.isArray(option)) {
        return option.map(o => o.value);
    }
    return (option as Option<T>)?.value;
}

function getSelectStyles(errorMessage?: string, disabled?: boolean, isMulti?: boolean) {
    return {
        menu: () => "bg-white border rounded-md border-grey-light overflow-hidden w-max",
        option: (state: { isSelected: boolean; isDisabled: boolean }) =>
            classNames("p-2 cursor-pointer", {
                "bg-blue-extra-light": state.isSelected && !state.isDisabled,
                "hover:bg-grey-extra-light": !state.isSelected && !state.isDisabled,
                "text-grey-light cursor-not-allowed": state.isDisabled
            }),
        control: (state: { isFocused: boolean }) =>
            classNames("min-h-8 border rounded-md px-2 cursor-pointer placeholder:text-grey-light", {
                "border-slate-primary shadow-none": state.isFocused && !errorMessage && !disabled,
                "bg-grey-extra-light text-grey-primary": disabled,
                "border-danger": errorMessage && !disabled,
                "bg-white border-grey-light text-slate-dark": !errorMessage && !disabled,
                "h-auto": isMulti
            }),
        multiValue: () => "h-auto bg-grey-extra-light rounded-md mr-1 text-slate-primary px-2",
        multiValueRemove: () => "order-first",
        valueContainer: () => "gap-1",
        clearIndicator: () => "cursor-pointer",
        menuPortal: () => "z-50",
        placeholder: () => "text-grey-primary"
    };
}

function BaseSelect<T, IsMulti extends boolean = false>({ isDisabled, onChange, errorMessage, icon: Icon, ...props }: SelectProps<T, IsMulti>) {
    const defaultValue = (props.options as Option<T>[]).filter(option => {
        if (props.isMulti) {
            return (props.defaultValue as T[])?.includes(option.value);
        }
        return option.value === props.defaultValue;
    });

    const selectedValue = (props.options as Option<T>[])?.filter(option => {
        if (props.isMulti) {
            return (props.value as T[])?.includes(option.value);
        }
        return option.value === props.value;
    });

    const selectStyles = getSelectStyles(errorMessage, isDisabled);

    function DropdownIndicator(dropdownIndicatorProps: DropdownIndicatorProps<Option<T>, IsMulti, GroupBase<Option<T>>>) {
        return (
            <components.DropdownIndicator {...dropdownIndicatorProps}>{Icon && <Icon color={isDisabled ? "grey" : "slate"} />}</components.DropdownIndicator>
        );
    }

    return (
        <ReactSelect
            menuPosition="fixed"
            {...props}
            onChange={option => onChange(getValue<T, IsMulti>(option))}
            value={selectedValue}
            isOptionDisabled={option => !!option.disabled}
            defaultValue={defaultValue}
            unstyled
            isDisabled={isDisabled}
            classNames={selectStyles}
            components={{ ...props.components, DropdownIndicator }}
        />
    );
}

function BaseCreatableSelect<T, IsMulti extends boolean, Group extends GroupBase<Option<T>>>({
    isDisabled,
    onChange,
    errorMessage,
    isMulti,
    ...props
}: CreatableProps<T, IsMulti, Group>) {
    const selectStyles = getSelectStyles(errorMessage, isDisabled, isMulti);

    return (
        <RSCreatableSelect
            {...props}
            unstyled
            className="w-full"
            onChange={option => onChange(getValue<T, IsMulti>(option))}
            classNames={selectStyles}
            isMulti={isMulti}
            value={props.value?.map(value => ({ value, label: value })) as Option<T>[]}
            isDisabled={isDisabled}
        />
    );
}

export namespace BaseSelects {
    export type CountriesCallingCodesProps = {
        data: {
            name: string;
            countryCode: string;
        }[];
    };

    export function Select<T, IsMulti extends boolean = false>({
        label,
        isDisabled,
        options,
        required,
        className,
        errorMessage,
        rightChildren,
        "data-id": dataId,
        ...props
    }: SelectProps<T, IsMulti>) {
        return (
            <div className={classNames("flex flex-col space-y-1", className)}>
                {label && <Label disabled={isDisabled} label={label} required={required} />}
                <div className="flex items-center gap-2">
                    <div className="relative flex flex-1 items-center" data-id={dataId}>
                        <BaseSelect {...props} className="w-full" options={options} isDisabled={isDisabled} errorMessage={errorMessage} />
                    </div>
                    {rightChildren && <div>{rightChildren}</div>}
                </div>
                {errorMessage && <ErrorMessage message={errorMessage} />}
            </div>
        );
    }

    export function CreatableSelect<T, IsMulti extends boolean, Group extends GroupBase<Option<T>>>({
        label,
        isDisabled,
        required,
        className,
        errorMessage,
        ...props
    }: CreatableProps<T, IsMulti, Group> & { label?: React.ReactNode }) {
        return (
            <div className={classNames("flex flex-col", className)}>
                {label && <Label disabled={isDisabled} label={label} required={required} />}
                <div className="relative flex flex-1 items-center">
                    <BaseCreatableSelect {...props} isDisabled={isDisabled} required={required} />
                </div>
                {errorMessage && <ErrorMessage message={errorMessage} />}
            </div>
        );
    }
}
