/* eslint-disable max-len */
import { FullAudit } from "@vaultinum/vaultinum-api";
import filesize from "filesize";
import { CommonLang } from "../CommonLang";

const locale = "de-DE";
const formatSize = (size = 0): string => filesize(size, { base: 10, locale });

export const deCommon: CommonLang = {
    code: "de",
    getLang: "Deutsch",
    apps: { name: "Anwendungen" },
    shared: {
        // shared between authentication, account and apps
        email: "Email",
        copy: "Kopieren", // to review
        invalidEmail: "Ungültige E-Mail-Adresse",
        name: "Name",
        description: "Beschreibung",
        confirm: "Bestätigen",
        cancel: "Abbrechen",
        add: "Hinzufügen",
        save: "Speichern",
        edit: "Editieren",
        change: "Ändern",
        remove: "Entfernen",
        delete: "Löschen",
        free: "Frei",
        next: "Weiter",
        previous: "Zurück",
        yes: "Ja",
        no: "Nein",
        skip: "Überspringen",
        submit: "Absenden",
        done: "Fertig",
        back: "Zurück",
        unknown: "Unbekannt",
        loading: "Laden...",
        anyQuestion: "Haben Sie Fragen?",
        contactUs: "Kontakt",
        requiredErrorMessage: "Dieses Feld ist erforderlich",
        minErrorMessage: minLength => `mindestens ${minLength} Zeichen lang`,
        atLeastOneUpperLowercase: "mindestens ein Großbuchstabe und ein Kleinbuchstabe", // to review
        atLeastOneDigit: "mindestens eine Ziffer", // to review
        atLeastOneSpecialCharacter: "mindestens ein Sonderzeichen", // to review
        loginFailed: "Anmeldung fehlgeschlagen.",
        logoutFailed: "Abmeldung fehlgeschlagen.", // to review
        saveSuccessMessage: "Erfolgreich gespeichert",
        saveErrorMessage: "Speichern fehlgeschlagen",
        inviteSuccessMessage: "Einladung erfolgreich gesendet",
        inviteErrorMessage: "Einladung konnte nicht gesendet werden",
        sendEmailSuccessMessage: "E-Mail erfolgreich gesendet",
        sendEmailErrorMessage: "E-Mail konnte nicht gesendet werden",
        signIn: "Anmeldung",
        invalidBirthdate: "Bitte geben Sie ein korrektes Geburtsdatum ein",
        dateFormat: "MM/TT/JJJJ",
        successMessage: "Erfolg",
        failMessage: "Ausfall",
        password: "Passwort",
        confirmPassword: "Bestätigen Sie das Passwort",
        everything: "Alles", // to review
        updated: "Aktualisiert",
        update: "Aktualisierung",
        apply: "Anwenden", // to review
        logOutProgress: "Abmelden...", // to review
        emailMustBeDifferentFromYours: "Die E-Mail-Adresse muss sich von Ihrer unterscheiden", // to review
        details: "Einzelheiten", // to review
        uploadDescription: fullAudit => {
            if (fullAudit) {
                return "Manuelles Hochladen Ihrer Datei"; // to review
            } else {
                return "Hinterlegen Sie eine oder mehrere Dateien, komprimierte Dateien oder Ordner"; // to review
            }
        },
        uploadMethod: "Einzahlungsmethode", // to review
        uploadTitle: "Hochladen", // to review
        seeAllItems: appInfoLang => `${appInfoLang?.allItemsName || "Alle"} anzeigen`, // to review
        overview: "Überblick",
        selectedRepositories: {
            [1]: "1 depot geselecteerd", // to review
            plural: count => `${count} depots geselecteerd` // to review
        },
        selectManually: "Manuell auswählen", // to review
        upload: "Hochladen", // to review
        processing: "Verarbeitung",
        ready: "Bereit", // to review
        noResult: "kein Ergebnis", // to review
        included: "Enthalten", // to review
        search: "Suche", // to review
        results: {
            [0]: "0 ergebnis", // to review
            [1]: "1 ergebnis", // to review
            plural: count => `${count} ergebnisse` // to review
        },
        characters: {
            [0]: "0 charakter", // to review
            [1]: "1 charakter", // to review
            plural: count => `${count} charaktere` // to review
        },
        invalidPhoneNumber: "Ungültige Telefonnummer", // to review
        other: "Sonstige",
        filterBy: (label: string) => `Filtern nach ${label.toLocaleLowerCase()}`, // to review
        sortBy: (label: string) => `Sortieren nach ${label.toLocaleLowerCase()}`, // to review
        ascending: "Aufsteigend", // to review
        descending: "Absteigend", // to review
        organisation: "Organisation", // to review
        domain: "Domain", // to review
        status: "Status", // to review
        empty: "leer", // to review
        months: {
            january: "Januar", // to review
            february: "Februar", // to review
            march: "Marsch", // to review
            april: "April", // to review
            may: "Mai", // to review
            june: "Juni", // to review
            july: "Juli", // to review
            august: "August", // to review
            september: "September", // to review
            october: "Oktober", // to review
            november: "November", // to review
            december: "Dezember" // to review
        },
        changeFilter: "Um Ihre Suche zu erweitern, ändern oder entfernen Sie den obigen Filter", // todo to review
        selectAccount: "Wählen Sie ein Konto aus", // todo to review
        failedFetchingAccount: "Fehler beim Abrufen der Kontoinformationen. Bitte kontaktieren Sie unseren Kundensupport.", // to review
        others: "Andere", // to review
        noDataAvailable: "Keine Daten verfügbar" // to review
    },
    menu: {
        failedToGetMenuItems: "Beim Laden des Menüs ist ein Fehler aufgetreten" // to review
    },
    date: {
        on: "am",
        doneOn: () => "Erledigt am", // to review
        createdOn: () => "Erstellt am",
        updatedOn: () => "Aktualisiert am",
        completedOn: () => "Abgeschlossen am",
        requestedOn: () => "Angefordert am", // to review
        addedOn: () => "Hinzu am"
    },
    settingsMenu: {
        signOut: "Abmelden",
        signedInAs: "Angemeldet als:",
        companyAccount: "Organisation:", // to review
        settings: "Einstellungen", // to review
        profileSettings: "Profil Einstellungen",
        accountSettings: "Organisationseinstellungen", // to review
        switchTo: "Zu einem anderen Organisation wechseln:", // to review
        manageAccounts: "Organisationen verwalten", // to review
        newOrganization: "Neues Organisation" // to review
    },
    price: {
        allPlans: "Alle Pläne", // to review
        priceOnDemand: "Preis auf Nachfrage", // to review
        getStarted: "Buchen", // to review
        title: {
            accessClause: "Zugangsklausel", // to review
            bipartite: "Zweiparteienabkommen", // to review
            tripartite: "Dreiparteien-Übereinkommen", // to review
            deposit: "Hinterlegung", // to review
            depositUpdate: "Aktualisierung der Hinterlegung", // to review
            depositUnlimitedPlan: "Unbegrenzter Einzahlungsplan", // to review
            elementCheckedDeposit: "Geprüfte Hinterlegung", // to review
            contentCheckedDeposit: "Kontrollierte Hinterlegung", // to review
            oneShot: "One Shot" // to review
        },
        features: {
            fiveSurveys: "Fünf Umfragen", // to review
            multiUserAccess: "Multi-User-Zugang", // to review
            illustratedReport: "Bebilderter Bericht", // to review
            recommendations: "Empfehlungen", // to review
            customerSupport: "Kundenbetreuung", // to review
            oneSurvey: "Eine Umfrage", // to review
            unlimitedNumberOfSurvey: "Unbegrenzte Anzahl von Umfragen", // to review
            encryptedDeposit: "Verschlüsselte Hinterlegung", // to review
            certificateOfDeposit: "Bescheinigung über die Hinterlegung", // to review
            freeUpdates: "Freie Updates", // to review
            contractTemplate: "Vertragsvorlage", // to review
            legalTermsAtYourService: "Rechtsberaterteam zur Verfügung", // to review
            beneficiaryDashboardAccess: "Zugang zum Dashboard für Berechtigte", // to review
            vaultinumAsSignator: "Vaultinum als Unterzeichner", // to review
            oneOnlineAssessment: "Eine Selbstbeurteilung", // to review
            sourceCodeScan: "Quellcode-Scan", // to review
            expertAnalysis: "Experten-Analyse", // to review
            multipleProducts: "Mehrere Produkte", // to review
            multipleCompanies: "Mehrere Unternehmen" // to review
        }
    },
    payment: {
        checkout: {
            name: "Checkout"
        },
        proceed: "Weiter zur Zahlung", // to review
        downloadReceipt: "Quittung herunterladen", // to review
        downloadReceiptError: "Beim Herunterladen der Quittung ist ein Fehler aufgetreten", // to review
        payment: {
            price: "Preis", // to review
            promoCode: "Promo-Code", // to review
            promoCodePlaceholder: "Ex: GPBM6PYXE",
            promoCodeError: "Dieser Promo-Code ist nicht gültig", // to review
            taxes: taxValue => `Steuern (${taxValue}% VAT)`, // to review
            discount: "Rabatt", // to review
            total: "Total", // to review
            name: "Zahlung",
            message: "Zahlungsmethode wählen:",
            creditCard: {
                name: "Kredit- oder Debitkarte", // to review
                cardName: "Kartenname", // to review
                saveAcceptance:
                    "Wenn Sie dieses Kästchen ankreuzen, erklären Sie sich damit einverstanden, diese Zahlungsmethode zu speichern, damit sie Ihnen bei zukünftigen Bestellungen angeboten wird. Dies ersetzt jedoch nicht Ihre derzeitige Standardzahlungsmethode."
            },
            sepaDebit: {
                name: "SEPA-Lastschrift", // to review
                accountHolder: {
                    name: "Name des Kontoinhabers",
                    namePlaceholder: "Vollständiger Name" // to review
                },
                iban: "IBAN",
                mandateAcceptance:
                    "Indem Sie Ihre Zahlungsinformationen angeben und diese Zahlung bestätigen, ermächtigen Sie (A) Vaultinum und Stripe, unseren Zahlungsdienstleister, Anweisungen an Ihre Bank zu senden, um Ihr Konto zu belasten, und (B) Ihre Bank, Ihr Konto gemäß diesen Anweisungen zu belasten. Als Teil Ihrer Rechte haben Sie Anspruch auf eine Erstattung von Ihrer Bank gemäß den Bedingungen Ihres Vertrags mit Ihrer Bank. Die Erstattung muss innerhalb von 8 Wochen ab dem Datum der Abbuchung von Ihrem Konto beantragt werden. Ihre Rechte werden in einer Erklärung erläutert, die Sie von Ihrer Bank erhalten können. Sie erklären sich damit einverstanden, dass Sie über künftige Abbuchungen bis zu 2 Tage im Voraus benachrichtigt werden."
            },
            activationCode: {
                name: "Aktivierungscode",
                message: "Geben Sie Ihren Aktivierungscode ein:",
                placeholder: "Aktivierungscode..."
            },
            method: "Zahlungsmethode" // to review
        },
        done: {
            name: "Fertig",
            message: "Ihr Kauf wurde mit Erfolg abgeschlossen!"
        },
        failed: {
            name: "Gescheitert",
            message: "Bei Ihrer Zahlung ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
            backToPayment: "Zurück zu Zahlung",
            unableToConfirmCard: "Karte kann nicht bestätigt werden.",
            callbackError:
                "Es gab ein Problem während des Zahlungsabschlusses. Sie wurden bereits belastet. Wenn Sie erneut versuchen zu bezahlen, werden Sie möglicherweise doppelt belastet. Bitte kontaktieren Sie uns, damit wir Ihnen einen kostenlosen Gutschein geben können, bevor Sie es erneut versuchen." // to review
        },
        billing: {
            address1: "Adresse Zeile 1",
            address: "Adresse",
            billingInformation: "Informationen zur Rechnungsstellung",
            city: "Stadt",
            country: "Land",
            companyName: "Name des Unternehmens",
            vatNumber: "Umsatzsteuer-Identifikationsnummer",
            vatNumberPlaceholder: "DE123456789",
            wrongVatFormat: "Die Umsatzsteuer-Identifikationsnummer ist im falschen Format", // to review
            taxIdRegistered: "Bereits registrierte Umsatzsteuer-Identifikationsnummer", // to review
            notProvided: "Nicht bereitgestellt", // to review
            zipCode: "Postleitzahl",
            billingDisclaimer: "Ihre Angaben werden für den Kauf verwendet. Bitte stellen Sie sicher, dass keine Angaben fehlen und dass sie korrekt sind.",
            formWarningMissingDetails: "Erforderliche Rechnungsangaben fehlen",
            billingContact: "Rechnungskontakt", // to review
            billingContactPlaceholder: "E-Mail des Rechnungskontakts eingeben" // to review
        },
        frequency: {
            day: "Tag",
            week: "Woche",
            month: "Monat",
            year: "Jahr"
        },
        summary: {
            order: "Bestellübersicht", // to review
            billingAddress: "Rechnungsadresse", // to review
            paymentMethod: "Zahlungsmethode", // to review
            disclaimer: "Sie erhalten eine Bestätigungs-E-Mail mit Bestelldetails" // to review
        },
        method: {
            expired: "Abgelaufen", // to review
            addPaymentMethod: "Zahlungsmethode hinzufügen", // to review
            expirationDate: "Ablaufdatum", // to review
            errorListingPaymentMethods: "Beim Abrufen der Zahlungsmethodenliste ist ein Fehler aufgetreten", // to review
            errorAddingPaymentMethod: "Beim Hinzufügen der Zahlungsmethode ist ein Fehler aufgetreten" // to review
        }
    },
    industryList: {
        businessConsultancyManagement: "Unternehmen, Beratung oder Management",
        accountancyBankingFinance: "Buchhaltung, Bankwesen oder Finanzen",
        energyUtilities: "Energie und Versorgungsunternehmen",
        engineeringManufacturing: "Ingenieurwesen oder verarbeitendes Gewerbe",
        environmentAgriculture: "Umwelt oder Landwirtschaft",
        softwarePublisher: "Software-Herausgeber",
        iTServices: "IT-Dienstleistungen",
        law: "Recht",
        lawEnforcementSecurity: "Strafverfolgung und Sicherheit",
        leisureHospitalityTourism: "Freizeit, Gastgewerbe oder Tourismus",
        mediaDigitalPublicRelations: "Medien, Digitales, PR",
        propertyConstruction: "Immobilien oder Bauwesen",
        publicServicesAdministration: "Öffentliche Dienste oder Verwaltung",
        scientificResearch: "Wissenschaftliche Forschung",
        pharmaceuticalsHealthcare: "Pharmazeutika, Gesundheitswesen",
        education: "Bildung",
        transportLogistics: "Transport oder Logistik",
        other: "Sonstige"
    },
    cookieConsent: {
        accept: "Annehmen",
        decline: "Ablehnen",
        description:
            "Wir verwenden Cookies, um den Verkehr zu analysieren und den Verkehr und die Benutzererfahrung zu verbessern. Sie können unsere rechtlichen Hinweise lesen, um mehr darüber zu erfahren, wie wir mit Daten umgehen."
    },
    survey: {
        preamble: "Präambel",
        resetQuestion: "Antwort zurücksetzen",
        extraInformation: "Zusätzliche Informationen...",
        pleaseDescribe: "(Bitte geben Sie eine Beschreibung)",
        quickFix: "Schnelle Lösung", // to review
        longFix: "Lange Behebung", // to review
        evaluationImpact: {
            critical: "Kritische Auswirkung", // to review
            high: "Hohe Auswirkung", // to review
            low: "Gering Auswirkung" // to review
        },
        priority: "Priorität", // to review
        affects: "Betrifft", // to review
        status: "Status", // to review
        pending: "Schwebend", // to review
        updated: "Aktualisiert", // to review
        obsolete: "Überholt", // to review
        updateAnswer: "Antwort aktualisieren", // to review
        updateAnswerError: "Beim Aktualisieren der Antwort ist ein Fehler aufgetreten", // to review
        updatedByReviewer: "Aktualisiert von einem Vaultinum-Experten", // to review
        yourInitialAnswer: "Ihre erste Antwort", // to review
        initialAnswer: "Erste Antwort", // to review
        updateYourCurrentAnswer: "Aktualisieren Sie Ihre aktuelle Antwort", // to review
        isObsolete: "Diese Empfehlung ist jetzt überholt", // to review
        obsoleteReason: "Diese Frage existiert in der aktuellen Version der Umfrage nicht mehr", // to review
        beforeStart: "Bevor wir beginnen",
        domainExtension: "Domänenerweiterung…", // to review
        countryList: "Länderliste…", // to review
        trademarkClass: "Markenklassen...", // to review
        filterQuestionWith: "Fragen mit...", // to review
        questionWithNotes: "Notizen", // to review
        questionWithRecommendations: "Empfehlungen", // to review
        sections: "Abschnitte", // to review
        recommendations: "Empfehlungen" // to review
    },
    report: {
        completed: "Abgeschlossen",
        industryAverage: "Branchendurchschnitt", // to review
        score: "Ergebnis" // to review
    },
    fileTree: {
        lines: "Linien", // to review
        size: "Größe" // to review
    },
    codeInput: {
        codeValidationMessage: "Code verifizieren...", // to review
        codeValidationMessageError: "Ungültiger Code, bitte versuchen Sie es erneut.", // to review
        codeValidationMessageSuccess: "Code validiert." // to review
    },
    register: {
        acceptTermsConditionsAndPrivacyPolicyErrorMessage: "Die Allgemeinen Geschäftsbedingungen und die Datenschutzrichtlinie müssen akzeptiert werden.",
        alreadyRegistered: "Bereits registriert?",
        confirmPasswordErrorMessage: "Passwörter stimmen nicht überein",
        createAccount: "Konto erstellen",
        iAcceptThe: "Ich akzeptiere die",
        join: company => `Beitreten ${company}`,
        login: "Anmeldung",
        logout: "Abmelden",
        privacyPolicy: "die Datenschutzrichtlinie",
        register: "Registrieren Sie sich",
        registerWelcome: "Füllen Sie das folgende Formular aus, um ein neues Konto zu erstellen.",
        termsConditions: "Allgemeine Bedingungen für die Nutzung der Plattform",
        failMessage: "Registrierung fehlgeschlagen" // to review
    },
    onboarding: {
        title: "Willkommen bei Vaultinum!",
        subtitle: "Unabhängiger Treuhänder, spezialisiert auf den Schutz und die Prüfung digitaler Vermögenswerte"
    },
    client: {
        supplier: "Lieferant", // todo to review
        beneficiary: "Begünstigter" // todo to review
    },
    fullAudit: {
        errors: {
            notFound: "Due Diligence nicht gefunden." // todo to review
        },
        status: {
            [FullAudit.Status.OPEN]: "Offen", // todo to review
            [FullAudit.Status.IN_PROGRESS]: "In Bearbeitung", // todo to review
            [FullAudit.Status.BLOCKED]: "Blockiert", // todo to review
            [FullAudit.Status.CANCELLED]: "Abgebrochen", // todo to review
            [FullAudit.Status.DONE]: "Erledigt" // todo to review
        }
    },
    parties: {
        // to review
        newParty: "Neue Partei",
        sendInvitation: "Einladung senden",
        sendInvitationByEmail: "Einladung per E-Mail senden", // to review
        inputSupplierEmail: "Bitte geben Sie die E-Mail Ihres Ansprechpartners an:",
        invitation: companyName =>
            `Laden Sie Ihre Kontaktperson ein, eine Partei von “${companyName}” zu werden, und beginnen Sie mit der Zusammenarbeit bei Escrows und/oder Audits. Ihr neuer Partei wird in der Lage sein, die gemeinsamen Informationen über Audits/Treuhänderschaften sowie den Namen, die Adresse und die Registrierungsnummer Ihres Unternehmens einzusehen. Wenn Ihr Kontakt noch kein Vaultinum-Konto hat, wird er/sie eingeladen, eines zu erstellen. Kreuzen Sie das untenstehende Kästchen an, wenn Sie sie per E-Mail benachrichtigen wollen.`,
        alreadyPartner: "Diese E-Mail-Adresse ist bereits ein Partner Ihrer Organisation", // to review
        alreadyReceivedInvitation: "Diese E-Mail-Adresse hat bereits eine Einladung erhalten", // to review
        alreadySentInvitation: "An diese E-Mail-Adresse wurde bereits eine Einladung gesendet", // to review
        sendInvitationConfirmation: (email: string) => `Sind Sie sicher, dass Sie die Einladung an ${email} senden möchten?` // to review
    },
    accountInformation: {
        title: "Informationen zur Organisation",
        form: {
            companyName: "Name der Organisation",
            companyRegistrationNumber: "Registrierungsnummer",
            companyNationality: "Nationalität",
            companyIndustry: "Industrie",
            companySize: "Größe der Organisation",
            address: {
                title: "Adresse der Organisation", // to review
                line1: "Adresse", // to review
                line1PlaceHolder: "Straße Name, Nummer", // to review
                line2: "Adresse Zeile 2", // to review
                line2PlaceHolder: "Gebäude, Stockwerk", // to review
                city: "Stadt", // to review
                postalCode: "Postleitzahl", // to review
                postalCodePlaceHolder: "00000",
                country: "Land" // to review
            },
            billing: {
                invoiceCompanyName: "Name des in Rechnung gestellten Unternehmens", // to review
                billingInformationInvoiceCompanyName:
                    "Standardmäßig wird Ihr Firmenname auf Ihrer Rechnung angezeigt. Wenn Sie stattdessen einen benutzerdefinierten Namen anzeigen möchten, geben Sie ihn bitte hier ein:", // to review
                billingInformationInvoicePostalAddress: "Wenn Sie jeder Rechnung eine andere Postanschrift hinzufügen möchten, geben Sie diese hier ein", // to review
                billingInformationQuestions: "Haben Sie Fragen zu Ihrer Rechnungsstellung?" // to review
            }
        },
        roleAdminIsRequired: "Sie müssen die Rolle eines Administrators oder Vertreters haben, um die Unternehmensinformationen zu bearbeiten", // to review
        notes: {
            // to review all section
            title: "Notizen",
            deleteNote: "Notiz löschene",
            showNotes: "Notizen anzeigen",
            permanentlyDeleteNote: "Hiermit wird die Notiz endgültig gelöscht",
            visibleBy: "Sichtbar von",
            updatedBy: "Zuletzt aktualisiert von",
            reviewers: "rezensenten",
            membersOf: accountName => `mitglieder von ${accountName}`
        }
    },
    seo: {
        registerTitle: "Registrieren Sie sich auf der Online-Verwaltungsplattform", // to review
        registerDescription:
            "Erstellen Sie ein Organisation auf der Verwaltungsplattform und tätigen Sie eine sofortige IP-Hinterlegung, einen Software Escrow und eine technologische Due Diligence", // to review
        loginTitle: "Loggen Sie sich auf unserer Online-Verwaltungsplattform ein", // to review
        loginDescription:
            "Loggen Sie sich auf unserer Verwaltungsplattform ein und tätigen Sie eine sofortige IP-Hinterlegung, einen Software Escrow und eine technologische Due Diligence", // to review
        homeTitle: "Welche Aktion möchten Sie durchführen ? | Vaultinum", // to review
        homeDescription:
            "Die kollaborative Verwaltungsplattform ermöglicht eine IP Hinterlegung, ein Software Escrow oder eine technologische Due Diligence durchzuführen | ISO27001" // to review
    },
    git: {
        // to review all section
        gitConnection: "Git-Verbindung", // to review
        description: fullAudit => {
            if (fullAudit) {
                return "Laden Sie Ihren Code direkt von einem Git-Anbieter hoch"; // to review
            } else {
                return "Synchronisieren Sie Ihre Repositories in regelmäßigen Abständen oder aufgrund von Git-Ereignissen"; // to review
            }
        },
        notConfigured: "Es scheint, dass Sie noch keine Git-Verbindung eingerichtet haben",
        notConfiguredDetails:
            "Um Ihr verteiltes Online-Versionskontrollsystem (z. B. GitHub) einzurichten, klicken Sie auf die Schaltfläche unten und folgen Sie den Anweisungen. Kommen Sie hierher zurück, wenn das erledigt ist.",
        selectSource: "Quelle auswählen",
        selectRepository: "Codeverzeichnis(se) auswählen",
        selectBranch: "Zweig auswählen",
        selectFrequency: "Wählen Sie eine Frequenz",
        defaultBranch: "Standard-Zweig",
        frequency: {
            weekly: "Wöchentlich",
            monthly: "Monatlich",
            every3Month: "Alle 3 Monate",
            every6month: "Alle 6 Monate",
            oneShot: "One shot"
        },
        connectionActive: "Aktive Git-Verbindung", // to review
        errors: {
            connectionSuspended: "Git-Verbindung unterbrochen",
            connectionUninstalled: "Git-Verbindung deinstalliert",
            connectionNotAccessible: "Git-Verbindung nicht erreichbar",
            repositoryNotAccessible: "Git-Repository nicht zugänglich",
            repositoryNotFound: "Git-Repository nicht gefunden",
            branchDeleted: "Zweig gelöscht",
            archiveSizeExceeded: "Archivgröße überschreitet Limit",
            operationFailed: "Git-Operation fehlgeschlagen"
        },
        addRepository: "Repository hinzufügen",
        addAllRepositories: "Fügen Sie alle Codeverzeichnisse hinzu",
        branch: "auswählen",
        selectedRepositories: "Ausgewählte Repositorys",
        warningCheckoutConfirmation: "Nach der Bestätigung können Sie Titel, Beschreibung und ausgewählte Repositories nicht mehr bearbeiten",
        frequencyTitle: "Frequenz",
        summaryError: "Beim Abrufen von Informationen ist ein Fehler aufgetreten",
        codeRepository: "Codeverzeichnis",
        source: "Quelle",
        repositoriesError: "Beim Abrufen von Codeverzeichnissen ist ein Fehler aufgetreten",
        setup: "Konfiguration",
        codeRepositoryEmpty: "Bitte fügen Sie einige Code-Repositories hinzu und wählen Sie einen Zweig aus"
    },
    table: {
        apply: "Anwenden", // to review
        search: "Suchen", // to review
        filter: "Filtern", // to review
        sort: "Sortieren", // to review
        selectAll: "Alles auswählen", // to review
        clearAll: "Alles löschen", // to review
        sortAsc: "Nach aufsteigender Reihenfolge sortieren", // to review
        sortDesc: "Sortieren nach absteigender Reihenfolge" // to review
    },
    email: {
        subject: {
            billingSupportRequest: "Supportanfrage - Abrechnung", // to review
            supportRequest: "Supportanfrage" // to review
        }
    },
    uploadFile: {
        description: (fileType: string) => `Wählen Sie Ihre ${fileType}-Datei aus oder ziehen Sie sie per Drag & Drop`, // to review
        file: "Datei", // to review
        size: "Größe", // to review
        noFileUploaded: "Keine Datei hochgeladen", // to review
        preview: "Vorschau", // to review
        formatSize,
        tooManyFiles: "Es wird nur eine Datei akzeptiert", // to review
        invalidFileType: (fileName: string, fileTypes: string) => `Ungültiger Dateityp für ${fileName}. Gültige Dateitypen: ${fileTypes}`, // to review
        acceptedFileTypes: (fileTypes: string) => `Gültige Dateitypen: ${fileTypes}`, // to review
        actions: "Aktionen", // to review
        minResolution: (resolution: number) => `Empfohlene Mindestgröße: ${resolution}px (Breite/Höhe)`, // to review
        errorFetchingFiles:
            "Beim Abrufen von Dateien von unserem Server ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns, um dieses Problem zu beheben, bevor Sie versuchen, Dateien hochzuladen.",
        errorWhileUploading: "Fehler beim Hochladen Ihrer Datei. Bitte kontaktieren Sie unseren Kundensupport.",
        files: "Dateien",
        filesAlreadyExistError: files => `${files} existieren (t) bereits`,
        maxFileSizeError: (filename, maxFileSize) => `${filename} ist grosser als ${maxFileSize}`,
        maxNumberOfFilesError: maxNumberOfFiles => `Sie können nicht mehr als ${maxNumberOfFiles} files hochladen`,
        maxTotalFileSizeError: maxTotalFileSize => `Sie können nicht mehr als ${maxTotalFileSize} files hochladen`,
        minNumberOfFilesError: "Sie müssen mindestens eine Datei hochladen ",
        noFilesUploadedYet: "Noch keine Dateien hochgeladen",
        notPossibleToDelete: filename => `Es war nicht möglich, ${filename} zu löschen`,
        progress: "in Arbeit",
        uploadInstructions: "Ziehen Sie Ihre Dateien hierher, oder klicken Sie, um Dateien auszuwählen.",
        uploadLimitations: (acceptedFileTypes, maxFileSize, maxTotalSize) =>
            `${
                !acceptedFileTypes?.length
                    ? "Alle Dateierweiterungen werden akzeptiert"
                    : `Nur bestimmte Dateierweiterungen werden akzeptiert: ${acceptedFileTypes}`
            }. Eine einzelne Datei darf nicht größer als ${maxFileSize} sein. Die Gesamtgröße darf ${maxTotalSize} nicht überschreiten.`, // to review
        waitUntilFinished: "Sie müssen warten, bis der Upload abgeschlossen ist"
    },
    chart: {
        monthAbbreviation: "M", // to review
        yearAbbreviation: "J" // to review
    }
};
